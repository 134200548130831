<template>
  <div class="header">
    <div class="header-logo">
      <img
        src="../assets/Images/header-logo.png"
        alt=""
      >
    </div>
    <div
      ref="routerBox"
      class="header-box"
    >
      <ul class="header-list">
        <li>
          <router-link
            :to="{ name: 'Home' }"
            exact
          >
            首页
          </router-link>
        </li>
<!--        <li>-->
<!--          <router-link :to="{ name: 'Application' }">-->
<!--            求职工作-->
<!--          </router-link>-->
<!--        </li>-->
        <li>
          <router-link :to="{ name: 'AboutUs' }">
            关于我们
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Culture' }">
            企业文化
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Product' }">
            产品方案
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'Style' }">
            企业风采
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'News' }">
            新闻资讯
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'JoinUs' }">
            加盟我们
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ContactUs' }">
            联系我们
          </router-link>
        </li>
      </ul>
    </div>
    <button
      class="header-btn"
      @click="showTabs"
    >
      导航
    </button>
  </div>
</template>
<script>
export default {
  data () {
    return {
      clientWidth: null
    }
  },
  watch: {
    $route () {
      // console.log("router", this.clientWidth);
      if (this.clientWidth < 900) {
        this.$refs.routerBox.style.display = 'none'
      }
    }
  },
  mounted () {
    this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
    window.addEventListener('resize', () => {
      this.clientWidth = document.documentElement.clientWidth || document.body.clientWidth
      // console.log("headerclient", this.clientWidth);
      if (this.clientWidth >= 900) {
        this.$refs.routerBox.style.display = 'block'
      } else {
        this.$refs.routerBox.style.display = 'none'
      }
    })
  },
  methods: {
    showTabs () {
      if (this.$refs.routerBox.style.display === 'block') {
        this.$refs.routerBox.style.display = 'none'
      } else {
        this.$refs.routerBox.style.display = 'block'
      }
    }
  }
}
</script>

<style lang="less">
.header {
  display: flex;
  align-items: center;
  /* 头部tab列表 */
  .header-list {
    display: flex;
    align-items: center;
    li {
      a {
        display: block;
        padding: 20px 15px 30px 15px;
        color: @global-tab-color;
        font-size: 16px;
        letter-spacing: 2px;
        // &.router-link-exact-active {
        //   font-weight: bold;
        //   color: @global-tab-active-color;
        //   border-bottom: 3px solid @global-tab-active-color;
        // }
        &.router-link-active {
          font-weight: bold;
          color: @global-tab-active-color;
          border-bottom: 3px solid @global-tab-active-color;
        }
      }
    }
  }
}
// pc端应用
@media screen and (min-width: 1100px) {
  .header {
    padding-top: 30px;
    justify-content: center;
    /* logo */
    .header-logo {
      margin-right: 100px;
      img {
        height: 30px;
      }
    }
    /* 导航条 */
    .header-box {
      display: block;
      .header-list {
        margin-bottom: 0;
        // margin-right: 300px;
        li {
          padding: 0 5px;
        }
      }
    }
    /* 导航按钮 */
    .header-btn {
      display: none;
    }
  }
}
// pc端和手机端之间
@media screen and (min-width: 900px) and (max-width: 1099px) {
  .header {
    padding-top: 30px;
    justify-content: center;
    /* logo */
    .header-logo {
      img {
        height: 23px;
      }
    }
    /* 导航条 */
    .header-box {
      display: block;
      .header-list {
        margin-bottom: 0;
      }
    }
    /* 导航按钮 */
    .header-btn {
      display: none;
    }
  }
}
// 手机端应用
@media screen and (max-width: 899px) {
  .header {
    padding: 20px 30px;
    box-sizing: border-box;
    justify-content: space-between;
    position: relative;
    /* logo */
    .header-logo {
      img {
        height: 23px;
      }
    }
    /* 导航条 */
    .header-box {
      display: none;
      width: 100%;
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 1000;
      .header-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #fff;
        padding-left: 0;
        border-top: 1px solid #f2f2f2;
        li {
          width: 100%;
          border-bottom: 1px solid #f2f2f2;
        }
      }
    }
    /* 导航按钮 */
    .header-btn {
      display: block;
      border: 1px solid #ccc;
      background: #fff;
      padding: 6px 16px;
      border-radius: 5px;
      font-size: 14px;
    }
  }
}
</style>
