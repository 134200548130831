<template>
  <div class="footer-container">
    <div class="footer-address">
      地址: 上海市嘉定区安亭镇墨玉路嘉正国际大厦 <span>沪ICP备15046583号-13</span> <span>找劳务就找劳务邦</span>
    </div>
    <div class="footer-copy">
      版权所有: 上海老邦网络科技有限公司 <span>Copyright 2017-2019</span><span>Laobour Group All Rights Reserved</span>
    </div>
    <div class="footer-links">
      <span>友情链接：</span>
      <div>
        <a
          v-for="(item, index) in links"
          :key="index"
          :href="item.link"
          target="_blank"
        >{{ item.name }}</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      links: []
    }
  },
  mounted () {
    this.getLinks()
  },
  methods: {
    // 友情链接
    getLinks () {
      this.$store.dispatch('getLinks').then(res => {
        res.forEach(item => {
          this.links.push(item)
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.footer-container {
  width: 100%;
  background-image: url("../assets/Images/foot-about-bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  .footer-address {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    span {
      margin-left: 10px;
    }
  }
  .footer-copy {
    margin-top: 5px;
    span {
      margin-left: 10px;
    }
  }
  .footer-links {
    margin-top: 15px;
    display: flex;
    span {
      display: block;
    }
    div {
      a {
        color: #fff;
        letter-spacing: 1px;
        margin-left: 10px;
      }
    }
  }
}
@media screen and (min-width: 700px) {
  .footer-container {
    height: 300px;
    .footer-address {
      line-height: 25px;
      font-size: 14px;
    }
    .footer-copy {
      font-size: 15px;
      line-height: 25px;
    }
    .footer-links {
      span {
        width: 88px;
      }
      div {
        font-size: 14px;
      }
    }
  }
}
@media screen and (max-width: 700px) {
  .footer-container {
    padding: 30px 0;
    .footer-address {
      line-height: 18px;
      font-size: 12px;
      letter-spacing: 1px;
    }
    .footer-copy {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1px;
    }
    .footer-links {
      padding: 0 10px;
      box-sizing: border-box;
      span {
        width: 90px;
        font-size: 14px;
      }
      div {
        font-size: 14px;
        width: 100%;
        a {
          display: inline-block;
          padding: 3px 2px;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
