/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import * as actions from "./store/actions";
import * as mutations from "./store/mutations";
import * as getters from "./store/getters";

Vue.use(Vuex);

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  config.headers.Accept = `application/json`
  config.headers.ContentType = `application/x-www-form-urlencoded`
  // Vue.prototype.$loading({
  //   text: '请求中...'
  // })
  return config
}, function (err) {
  return Promise.reject(err)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // Vue.prototype.$loading().close()
  // 对响应数据做点什么
  return response;
}, function (error) {
  // console.log('error', error)
  // Vue.prototype.$loading().close()
  // 对响应错误做点什么
  return Promise.reject(error);
})

Vue.prototype.$http = axios;

export default new Vuex.Store({
  mutations,
  actions,
  getters
});
