<template>
  <div id="app">
    <div id="nav">
      <Header />
    </div>
    <router-view />
    <footer-about />
  </div>
</template>
<script>
import Header from './components/Header.vue'
import FooterAbout from '@/components/FooterAbout.vue'
export default {
  components: {
    Header,
    FooterAbout
  }
}
</script>
<style lang="less">
@import "./assets/css/common.less";
@import "./assets/css/theme.less";
@import "./assets/css/elementChange.less";
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
/* 汉仪雪君体简 */
@font-face {
  font-family: "joinFamily";
  src: url("./assets/font/HYXueJunTiJ.ttf");
}
</style>
